import React from 'react'
import PromotionCategory from 'templates/promotion-category'
import { graphql } from 'gatsby'

const ServicePromotions = props => {
  return <PromotionCategory {...props} category='service' />
}

export const pageQuery = graphql`
  {
    allHutsonPromotion(
      filter: { tags: { in: ["service"] } }
      sort: { fields: [endDate], order: ASC }
    ) {
      nodes {
        endDate
        endDateString
        excerpt
        id
        image: localImage {
          ...SharpImage640
        }
        link
        slug
        tags
        title
      }
    }
  }
`

export default ServicePromotions
